
import { Component, Vue } from 'vue-property-decorator';
import Drawer from '@/components/Drawer.vue';
import { $config, $content } from "@/services";


@Component({
  components: {
    Drawer
  },
})
export default class Home extends Vue {

  currentPhoto:string|null=null;

  $refs!: {
    intro: HTMLInputElement;
  };
  
  get config() {
    return $config.config;
  }

  get content() {
    return $content.data;
  }
  mounted(){
    const url = $content.data.intro_image.path;
    this.$refs.intro.style.backgroundImage = "url("+url+")";
    // if(!this.config.show_cursor){
    //   document.body.parentElement!.style.cursor = "none";
    // }
  }

}
